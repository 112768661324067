import { getAPIObjFor } from '@/lib/ymm/YMMApi'

const YMMSelectionData = () => {
  const getHotspotData = (_parts, _engineId) => {
    const hotspotRef = _parts.reduce((_refStr, _part) => {
      if (!_part.assets || _part.assets.length < 1) {
        return _refStr
      }

      let assetRef = ''
      if (_engineId) {
        const asset = _part.assets.find((_asset) => _asset.EngineBaseID === _engineId)
        if (asset) {
          assetRef = asset.AssetName
        }
      } else {
        assetRef = _part.assets?.[0]?.AssetName
      }

      if (assetRef) { _refStr = assetRef }
      return _refStr
    }, '')

    return hotspotRef === '' ? false : hotspotRef
  }

  const getPartCategories = (_parts) => {
    const orderMap = {
      'Exhaust System Kit': 0,
      'Custom Builder Parts': 1
    }
    const partCategories = _parts.reduce((_partsObj, _part) => {
      const partType = _part.part_type.name === 'Exhaust System Kit' ? 'Exhaust System Kit' : 'Custom Builder Parts'
      if (_partsObj[partType] === undefined) {
        _partsObj[partType] = []
        _partsObj[partType].order = orderMap[partType]
      }
      _partsObj[partType].push(_part)
      _partsObj[partType].sort((a, b) => {
        const aHotspotRef = parseInt(a.assets && a.assets.length > 0 ? a.assets[0].AssetItemRef === '' ? '0' : a.assets[0].AssetItemRef : '0')
        const bHotspotRef = parseInt(b.assets && b.assets.length > 0 ? b.assets[0].AssetItemRef === '' ? '0' : b.assets[0].AssetItemRef : '0')
        return (aHotspotRef > bHotspotRef) ? -1 : 1
      })
      return _partsObj
    }, {})
    let sortedCollection = Object.keys(partCategories).sort((cur, prev) => {
      if (orderMap[cur] < orderMap[prev]) return -1
      else return 1
    })
    sortedCollection = sortedCollection.map((item) => { return { [item]: partCategories[item] } })
    return sortedCollection
  }

  const getCollectionName = (_selections) => {
    const collectionName = ymmStates.reduce((_str, _state, index) => {
      const seperator = index !== 4 ? ' ' : ''
      const stateValue = _selections[_state]?.label || ''
      if (stateValue !== '')_str += `${stateValue}${seperator}`
      return _str
    }, '').toUpperCase()

    return collectionName
  }

  const getYmmId = ({ Year, Make, Model, SubModel, Engine }) => {
    return `${Year.value}-${Make.value}-${Model.value}-${SubModel.value}-${Engine.value}`
  }

  const getDropdownDataFromPartsRequest = (_carConfig, _unqualifiedParts) => {
    // TODO fix middleware to provide correct engine information on parts response
    const vehicle = _unqualifiedParts.Vehicles[0]
    const getEngineLabel = () => {
      const engine = vehicle.engine_configs[0].engine_base
      return 'V' + engine.cylinders + ' ' + engine.liter + 'L '
    }
    // TODO:: update to not have single vehicle in array from middleware OR endpoint for ymm title
    _carConfig.Year.label = vehicle.base_vehicle.year.name
    _carConfig.Make.label = vehicle.base_vehicle.make.name
    _carConfig.Model.label = vehicle.base_vehicle.model.name
    _carConfig.SubModel.label = vehicle.sub_models[0].name
    _carConfig.Engine.label = getEngineLabel()
    return _carConfig
  }

  const getQualifiersFromUrl = () => {
    const paramsFromUrl = window.location.href.split('?')[1]
    const params = new URLSearchParams(paramsFromUrl)
    return params.get('qualifiers')
  }

  /// ////////// PUBLIC FUNCTIONS /////////////
  const getCollectionData = (_ymmData) => {
    const hotspotData = getHotspotData(_ymmData.parts, _ymmData.dropdownSelections?.Engine?.value)
    const partCategories = getPartCategories(_ymmData.parts)
    const collectionName = getCollectionName(_ymmData.dropdownSelections)

    return { hotspotData, partCategories, collectionName }
  }

  const getCollectionSlug = (_selections, _chosenQualifiers) => {
    const ymmTitle = ymmStates.reduce((_str, _state, index) => {
      const seperator = index !== 4 ? '-' : ''
      const stateValue = _selections[_state]?.label.replace(/-/g, '') || false
      if (stateValue && _str !== false) {
        _str += `${stateValue}${seperator}`.replace(/ /g, '')
      } else {
        _str = false
      }
      return _str
    }, '')
    let qualifiers = _chosenQualifiers.reduce((_str, qualifier) => {
      _str += qualifier.name + ':' + qualifier.value + ','
      return _str
    }, '')
    qualifiers = qualifiers.slice(0, -1)
    const ymmId = getYmmId(_selections)
    const slug = '/collection/' + encodeURIComponent(ymmTitle) + '?qualifiers=' + qualifiers + '&id=' + ymmId
    return { slug, qualifiers, ymmTitle, ymmId }
  }

  const getPDPData = (_selectedPart = []) => {
    const pdpTitlesMap = {
      'Exhaust System Kit': 'DIRECT-FIT EXHAUST SYSTEM'
    }
    // Gallery Assets Data Formatting
    // const digitalAssets = _selectedPart?.digital_assets || []
    // const galleryAssetsSorted = digitalAssets.reduce((_arr, _asset) => {
    //   const isHero = _asset.AssetType.includes('P04')
    //   const isGalImage = parseInt(_asset.AssetType.split('ZZ')[1]) <= 4
    //   if (isHero) {
    //     _asset.galPosition = 0
    //   } else if (isGalImage) {
    //     _asset.galPosition = parseInt(_asset.AssetType.split('ZZ')[1])
    //   } else {
    //     return _arr
    //   }
    //   _arr.push(_asset)
    //   _arr.sort((cur, next) => {
    //     if (cur.galPosition < next.galPosition) { return -1 } else return 1
    //   })
    //   return _arr
    // }, [])
    // const galleryAssets = galleryAssetsSorted.map(_asset => _asset.URI).slice(0, 3)
    const digitalAssets = _selectedPart?.digital_assets || []

    const excludedTypes = ['WAR', 'FAB', 'INS', 'LGO', 'AUD', '360']
    let assetOrder = ['P04', 'P05', 'P01', 'P07', 'P06', 'ZZ1', 'ZZ2', 'ZZ3', 'ZZ4', 'ZZ5', 'ZZ6', 'ZZ7', 'ZZ8', 'ZZ9', 'P02', 'P03']

    if (_selectedPart.part_type?.name?.toLowerCase().includes('catalytic')) {
      excludedTypes.push('VTT')
      assetOrder = ['P04', 'P05', 'P01', 'P07', 'P06', 'ZZ2', 'ZZ3', 'ZZ4', 'ZZ5', 'ZZ6', 'ZZ7', 'ZZ8', 'ZZ9', 'P02', 'P03']
    }

    const filteredAssets = digitalAssets?.filter(asset => !excludedTypes.includes(asset.AssetType))
    const sortedAndFilteredAssets = filteredAssets.sort((a, b) => {
      const aIndex = assetOrder.indexOf(a.AssetType)
      const bIndex = assetOrder.indexOf(b.AssetType)

      if (aIndex !== bIndex) {
        return aIndex - bIndex
      }

      // If both assets have types not defined in assetOrder, or are of the same type, sort by FileType
      if (a.FileType === 'MP4' && b.FileType !== 'MP4') return -1
      if (b.FileType === 'MP4' && a.FileType !== 'MP4') return 1

      // In case both have the same AssetType and FileType, or criteria not covered, they are considered equal
      return 0
    })
    const galleryAssets = sortedAndFilteredAssets.map(_asset => _asset.URI)

    // features icons formatting
    const featuresIcons = digitalAssets.reduce((_arr, _asset) => {
      const isFeaturesIcon = _asset.AssetType.includes('FAB')
      if (isFeaturesIcon) {
        _arr.push(_asset)
      }
      return _arr
    }, [])
    const installationGuide = digitalAssets.reduce((_str, _asset) => {
      if (_str) return _str
      if (_asset.AssetType === 'INS') _str = _asset.URI
      return _str
    }, null)

    const warrantyImage = digitalAssets.reduce((_str, _asset) => {
      if (_str) return _str
      if (_asset.AssetType === 'WAR') _str = _asset.URI
      return _str
    }, null)
    // get sku
    // const sku = _selectedPart?.sku?.azPartNumber || 'NA'
    const sku = (_selectedPart?.client_data?.length > 0 && _selectedPart.client_data[0].PartNumber) ? _selectedPart.client_data[0].PartNumber : 'NA'
    // get client url
    // const clientURL = _selectedPart?.clientdata?.Url || 'NA'
    const clientURL = (_selectedPart?.client_data?.length > 0 && _selectedPart.client_data[0].Urls?.length > 0 && _selectedPart.client_data[0].Urls[0]) ? _selectedPart.client_data[0].Urls[0] : 'NA'

    // description text
    const descriptionText = _selectedPart?.product_part?.descriptions?.reduce((_str, _descObj) => {
      if (_descObj.DescriptionCode === 'MKT') _str = _descObj.Text
      return _str
    }, '') || ''

    // BuildSpecs Data Formatting
    const buildTypeImg = digitalAssets.find(el => el.AssetType === 'ZZ6')?.URI || ''
    const isDIY = buildTypeImg.includes('DIY')
    const partNumber = _selectedPart?.PartNumber || ''
    const buildSpecs = {
      buildTypeImg,
      isDIY,
      specData: _selectedPart.product_part?.product_attributes || ''
    }

    // get pdp title
    const partType = _selectedPart?.part_type?.name || 'Please Select A Car Config'
    const pdpTitle = pdpTitlesMap[partType] || partType
    const pdpData = {
      galleryAssets,
      buildSpecs,
      partNumber,
      pdpTitle,
      featuresIcons,
      descriptionText,
      sku,
      installationGuide,
      warrantyImage,
      clientURL
    }

    return pdpData
  }

  const getPartDataFromUrlQuery = async (_query) => {
    let returnData = false
    let unqualifiedParts = false
    const partsQueryData = getAPIObjFor('Parts')
    const idParam = _query?.id?.split('-') || []
    if (idParam.length === 5) {
      const urlCarConfig = {
        Year: { value: idParam[0] },
        Make: { value: idParam[1] },
        Model: { value: idParam[2] },
        SubModel: { value: idParam[3] },
        Engine: { value: idParam[4] }
      }
      unqualifiedParts = await partsQueryData(urlCarConfig).then(data => data?.Vehicles?.length > 0 ? data : false).catch((error) => {
        console.error('ERROR requestiong data forom url', error)
        return false
      })
      if (unqualifiedParts) {
        const builtDropdownObject = getDropdownDataFromPartsRequest(urlCarConfig, unqualifiedParts)

        returnData = {
          unqualifiedParts,
          dropdownSelections: builtDropdownObject,
          qualifiers: getQualifiersFromUrl()
        }
      }
    }
    return returnData
  }

  const ymmStates = [
    'Year',
    'Make',
    'Model',
    'SubModel',
    'Engine'
  ]

  return {
    getCollectionData,
    getCollectionSlug,
    ymmStates,
    getPDPData,
    getPartDataFromUrlQuery
  }
}
export const ymmSelectionData = YMMSelectionData()

import axios from 'axios'
import { MiddlewareApiConfig, MiddlewareEndpointConfig } from '@/global.js'

const YMMAPI = MiddlewareApiConfig.ymmApiUrl
const BASEAPI = MiddlewareApiConfig.baseApiUrl

let ymmsAllData = null
let yearMakeData = null
let yearMakeModelData = null
let yearMakeModelSubModelData = null

const fetchAccessToken = async (clientKey) => {
  const token = await axios.post(`${BASEAPI}${MiddlewareEndpointConfig.accessToken}?clientKey=${clientKey}`).then(res => res.data).catch(async (error) => {
    console.error(error)
    return ''
  })
  return token
}

const isAccessTokenExpired = (token) => {
  try {
    const parts = token.split('.')
    const payload = JSON.parse(atob(parts[1]))

    if (!payload.exp) {
      return true
    }

    const currentTime = Math.floor(Date.now() / 1000)

    return payload.exp < currentTime
  } catch (error) {
    return true
  }
}

const getAccessToken = async () => {
  var token = localStorage.getItem('middlewareAccessToken')

  if (token) {
    if (isAccessTokenExpired(token)) {
      const newToken = await fetchAccessToken(token)
      localStorage.setItem('middlewareAccessToken', newToken)
      return newToken
    } else {
      return token
    }
  } else {
    const newToken = await fetchAccessToken(MiddlewareApiConfig.authClientKey)
    localStorage.setItem('middlewareAccessToken', newToken)
    return newToken
  }
}

const requestResource = async (_url) => {
  // eslint-disable-next-line no-unused-vars
  let errorMessage = false

  var token = await getAccessToken()
  const bearer = 'Bearer ' + token

  const request = await axios.get(_url, { headers: { Authorization: bearer } }).then(res => res.data).catch(async (error) => {
    errorMessage = error
    return false
  })

  // console.log('REQUESTING DATA FROM THIS RESOURCE:::', { URL: _url, PAYLOAD: request, ERROR: errorMessage })
  return request
}

// example: https://20230112mwmsmaindatasvc.azurewebsites.net/api/v2/autozone/YmmsAll
const ensureYmmsAllDataLoaded = async () => {
  if (ymmsAllData) {
    return
  }
  ymmsAllData = await requestResource(`${YMMAPI}${MiddlewareEndpointConfig.ymmsAll}`)
}

const getLabel = (_item, _labelKey) => {
  return _item[_labelKey]
}

const getValue = (_item, _valueKey, _labelKey) => {
  const valKey = _valueKey === '' ? _labelKey : _valueKey
  return _item[valKey]
}

const getYearsQuery = async () => {
  await ensureYmmsAllDataLoaded()
  const years = ymmsAllData.map((option) => ({
    value: getValue(option, 'YearID', 'YearID'),
    label: getLabel(option, 'YearID')
  }))
  const sortedYears = years.sort((a, b) => b.value - a.value) // Sort by year
  return sortedYears
}

const getMakesQuery = async (_groupState) => {
  await ensureYmmsAllDataLoaded()
  const selectDataYear = ymmsAllData.find(item => item.YearID === _groupState.Year.value)
  const jsonDataMakes = selectDataYear?.Makes || []
  yearMakeData = jsonDataMakes

  const uniqueMakes = new Set()
  const makes = jsonDataMakes.map(option => {
    const makeID = getValue(option, 'MakeID', 'MakeName')
    const makeName = getLabel(option, 'MakeName')

    if (!uniqueMakes.has(makeID)) {
      uniqueMakes.add(makeID)
      return { value: makeID, label: makeName }
    }

    return null
  }).filter(option => option !== null)

  const sortedMakes = makes.sort((a, b) => a.label.localeCompare(b.label))
  return sortedMakes
}

const getModelsQuery = async (_groupState) => {
  const selectedYearMake = yearMakeData.find(option => option.MakeID === _groupState.Make.value)
  const jsonDataModels = selectedYearMake?.Models || []
  yearMakeModelData = jsonDataModels

  const newModelsList = jsonDataModels.map(option => ({
    value: option.ModelID,
    label: option.ModelName
  }))
  const sortedModels = newModelsList.sort((a, b) => a.label.localeCompare(b.label))
  return sortedModels
}

const getSubModelsQuery = async (_groupState) => {
  const selectedYearMakeModel = yearMakeModelData.find(option => option.ModelID === _groupState.Model.value)
  const jsonDataSubModels = selectedYearMakeModel?.SubModels || []
  yearMakeModelSubModelData = jsonDataSubModels

  const newSubModelsList = jsonDataSubModels.map(option => ({
    value: option.SubModelID,
    label: option.SubModelName
  }))
  const sortedSubModels = newSubModelsList.sort((a, b) => a.label.localeCompare(b.label))
  return sortedSubModels
}

const getEnginesQuery = async (_groupState) => {
  const selectedYearMakeModelSubModel = yearMakeModelSubModelData.find(option => option.SubModelID === _groupState.SubModel.value)
  const jsonDataEngines = selectedYearMakeModelSubModel?.EngineLabelPartTypes || []

  const newEnginesList = jsonDataEngines.map((option) => ({
    value: option.EngineBaseID,
    label: option.EngineLabel
  }))

  const sortedEngines = newEnginesList.sort((a, b) => a.label.localeCompare(b.label))
  return sortedEngines
}

// example: https://20230112mwmsmaindatasvc.azurewebsites.net/api/v2/AutoZone/Parts/Search?vendorGroup=brexhaust&searchIdentifier=vendor&searchvalue=106-0745
// API Endpoint request values:
//    vendorGroup:  "magnaflow" or "brexhaust"
//    searchIdentifier: "magnaflow" or "vendor"
//    searchValue:  part number value
const getSearchQuery = async (searchValue, vendorGroup = 'brexhaust') => {
  let searchIdentifier = 'vendor'
  let searchedProductData = await requestResource(`${YMMAPI}${MiddlewareEndpointConfig.partSearch}?searchValue=${searchValue}&vendorGroup=${vendorGroup}&searchIdentifier=${searchIdentifier}`)
  let searchedPart = false
  if (searchedProductData?.Parts?.length > 0) {
    searchedPart = searchedProductData.Parts[0]
    searchedPart.sku = searchedPart?.client_data?.[0]?.PartNumber || ''
  } else {
    searchIdentifier = 'magnaflow'
    searchedProductData = await requestResource(`${YMMAPI}${MiddlewareEndpointConfig.partSearch}?searchValue=${searchValue}&vendorGroup=${vendorGroup}&searchIdentifier=${searchIdentifier}`)
    if (searchedProductData?.Parts?.length > 0) {
      searchedPart = searchedProductData.Parts[0]
      searchedPart.sku = searchedPart.PartNumber
    }
  }

  return searchedPart
}

const parseVehicles = (vehicles) => {
  const result = []

  vehicles.forEach((vehicle) => {
    const year = vehicle.base_vehicle.year.name
    const make = vehicle.base_vehicle.make.name
    const model = vehicle.base_vehicle.model.name

    // Deduplicate engine configurations based on liter
    const uniqueEngines = Object.values(
      vehicle.engine_configs.reduce((acc, engine) => {
        const liter = engine.engine_base.liter
        // Overwrite ensures unique by liter
        acc[liter] = engine.engine_base
        return acc
      }, {})
    )

    vehicle.sub_models.forEach((subModel) => {
      uniqueEngines.forEach((engine) => {
        const blockType = engine.block_type || 'V'
        const engineDescription = `${blockType.toUpperCase()}${engine.cylinders} ${engine.liter}L`

        result.push({
          Year: year,
          Make: make,
          Model: model,
          SubModel: subModel.name,
          Engine: engineDescription
        })
      })
    })
  })

  return result
}

// example: https://20230112mwmsmaindatasvc.azurewebsites.net/api/v2/autozone/Vehicles/ByPartNumber?partNumber=106-0485
const getCompatibleVehiclesQuery = async (partNumber) => {
  const data = await requestResource(`${YMMAPI}${MiddlewareEndpointConfig.vehiclesByPartNumber}?partNumber=${partNumber}`)
  const updatedCPVehicles = parseVehicles(data.Vehicles)

  // get our raw data and organize makes into letter object {'f':{letter:'f',data:{'ford':[car1,car2]}}}
  const formattedData = updatedCPVehicles.reduce((_obj, _car) => {
    const { Make } = _car

    const makeFirstLetter = Make.charAt(0).toLowerCase()
    const objContainsLetter = (makeFirstLetter in _obj)
    // if we dont have letter in our object, create a letter object for that letter
    if (!objContainsLetter) {
      _obj[makeFirstLetter] = { letter: makeFirstLetter, data: {} }
    }
    // if we dont have that make in that letter object, create that make object
    const letterObjContainsMake = (Make in _obj[makeFirstLetter].data)
    if (!letterObjContainsMake) {
      _obj[makeFirstLetter].data[Make] = []
    }
    _obj[makeFirstLetter].data[Make].push(_car)
    return _obj
  }, {})

  // requests cp data then alphabetize letters and makes within letters
  const alphabetizedCPbyMake = Object.keys(formattedData).reduce((arr, _letterKey) => {
    // get the current letter object wich contains the makes for that letter in 'data'
    const letterObj = formattedData[_letterKey]
    // alphabetize the makes keys within data
    const sortedDataKeys = Object.keys(letterObj.data).sort((cur, prev) => cur.toLowerCase() > prev.toLowerCase() ? 1 : -1)
    // create new data object based on the sorted keys
    const sortedMakes = sortedDataKeys.map((_make) => letterObj.data[_make])

    const sortedData = sortedMakes.map((_makeArr) => {
      return _makeArr.sort((a, b) => {
        // if years are different, put smallest year ahead
        if (a.Year !== b.Year) return a.Year < b.Year ? 1 : -1
        //  if years are same and models are different, put smallest model ahead
        if (a.Year === b.Year && a.Model !== b.Model) return a.Model < b.Model ? -1 : 1
        if (a.Year === b.Year && a.Model === b.Model && a.SubModel !== b.SubModel) return a.SubModel < b.SubModel ? -1 : 1
        if (a.Year === b.Year && a.Model === b.Model && a.SubModel === b.SubModel) return a.Engine < b.Engine ? -1 : 1

        else return 0
      })
    })
    // update existing data with sorted data
    letterObj.data = sortedData
    // add letterObj with sorted data to our letter array
    arr.push(letterObj)
    // sort letter array with new letter object added
    arr.sort((cur, prev) => cur.letter > prev.letter ? 1 : -1)

    return arr
  }, [])

  return alphabetizedCPbyMake
}

const parseVehicleConfig = async (vehicleConfig) => {
  if (!vehicleConfig.Year.label || !vehicleConfig.Make.label || !vehicleConfig.Model.label || !vehicleConfig.SubModel.label || !vehicleConfig.Engine.label) {
    await ensureYmmsAllDataLoaded()
    const selectedDataYear = ymmsAllData.find(item => item.YearID === vehicleConfig.Year.value)
    const yearLabel = vehicleConfig.Year.value
    vehicleConfig.Year.label = yearLabel
    const jsonDataMakes = selectedDataYear?.makes || []

    const selectedDataMake = jsonDataMakes?.find(option => option.MakeID === vehicleConfig.Make.value)
    const makeLabel = selectedDataMake?.MakeName
    vehicleConfig.Make.label = makeLabel
    const jsonDataModels = selectedDataMake?.Models || []

    const selectedDataModel = jsonDataModels.find(option => option.ModelID === vehicleConfig.Model.value)
    const modelLabel = selectedDataModel?.ModelName
    vehicleConfig.Model.label = modelLabel
    const jsonDataSubModels = selectedDataModel?.SubModels || []

    const selectedDataSubModel = jsonDataSubModels.find(option => option.SubModelID === vehicleConfig.SubModel.value)
    const subModelLabel = selectedDataSubModel?.SubModelName
    vehicleConfig.SubModel.label = subModelLabel
    const jsonDataEngines = selectedDataSubModel?.EngineLabelPartTypes || []

    const selectedDataEngine = jsonDataEngines.find(option => option.EngineBaseID === vehicleConfig.Engine.value)
    const engineLabel = selectedDataEngine?.EngineLabel
    vehicleConfig.Engine.label = engineLabel
  }
  return vehicleConfig
}

// example: https://20230112mwmsmaindatasvc.azurewebsites.net/api/v2/autozone/Parts/ByYmmsCategory?ymmsCatValue=2008-Ford-F150-XLT
const getPartsQuery = async (_groupState) => {
  const vehicleConfig = await parseVehicleConfig(_groupState)
  const vehicle = vehicleConfig.Year.label + '-' + vehicleConfig.Make.label + '-' + vehicleConfig.Model.label + '-' + vehicleConfig.SubModel.label
  const vehicleEncoded = encodeURIComponent(vehicle)
  const response = await requestResource(`${YMMAPI}${MiddlewareEndpointConfig.partsByYmmsCategory}?ymmsCatValue=${vehicleEncoded}`)
  return response
}

// example: https://20230112mwmsheadlessdatasvc.azurewebsites.net/api/v1/Cms/Microsite/Key?env=microsite-autozone
const getCMSApiKeyQuery = async (env = false) => {
  const response = await requestResource(`${BASEAPI}${MiddlewareEndpointConfig.cmsApiKey}?env=${env}`)
  return response
}

// old example: https://20230112mwmsmaindatasvc.azurewebsites.net/api/v1/Vehicles/MakeModel/Search?makeName=Ford&modelSubmodelValues=F-250%20Super%20Duty%20XLT%C2%A0Lariat
// example: https://20230112mwmsmaindatasvc.azurewebsites.net/api/v2/AutoZone/MakeModel/Search?year=2021&makeName=ford&modelSubmodelValues=f-150
const getMakeModelSearchQuery = async (year, makeName, modelSubmodelValues) => {
  const response = await requestResource(`${YMMAPI}${MiddlewareEndpointConfig.makeModelSearch}?year=${year}&makeName=${makeName}&modelSubmodelValues=${modelSubmodelValues}`)
  return response
}

const queriesMap = {
  Year: getYearsQuery,
  YearAPI: getYearsQuery,
  Make: getMakesQuery,
  Model: getModelsQuery,
  SubModel: getSubModelsQuery,
  Engine: getEnginesQuery,
  Parts: getPartsQuery,
  CompatibleVehicles: getCompatibleVehiclesQuery,
  Search: getSearchQuery,
  CMSApiKey: getCMSApiKeyQuery,
  MakeModel: getMakeModelSearchQuery
}

const getAPIObjFor = (_componentName) => {
  return queriesMap[_componentName]
}
export {
  getAPIObjFor
}

<template>
  <div class="ymm-qualifier-container ymm-inner">
    <div class="center-container">
      <div class="ymm-header"><h1>Vehicle Details</h1></div>
      <p>
        Select the option that best describes your vehicle. We want to make sure
        the part fits your specific vehicle so we need a little more information
        from you.
      </p>
      <div class="question-container">
        <el-radio-group
          v-for="(q, index) in questionData"
          ref="radioGroupRef"
          v-model="q.value"
          :key="q + index"
          :class="`radio-${index}`"
          @change="radioChanged(this)"
        >
          <el-radio
            v-for="(option, index) in q.options"
            :key="option + q.question + index"
            :label="index"
          >
            <div class="base-question">{{ q.question }}</div>
            <div class="select-label">{{ option }}</div>
          </el-radio>
        </el-radio-group>
      </div>
      <Button
        :disabled="nextBtnDisabled"
        @click="optionSelected"
        >Next
      </Button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { ymmQualifiers } from '@/lib/ymm/YMMQualifiers'
import gsap from 'gsap'
import { useMutations } from '@/lib/helpers'
import Button from '@/components/buttons/Button'
import { ymmSelectionData } from '@/lib/ymm/YMMSelectionData'
import { useRouter } from 'vue-router'

export default {
  components: { Button },
  setup () {
    const questionData = ref([])
    const radioGroupRef = ref(null)
    const nextBtnDisabled = ref(true)
    const qualifiersOpen = ref(false)
    const { setYMMQualifiedData } = useMutations(['setYMMQualifiedData'])
    const router = useRouter()
    const qualifiedData = {}
    let hasQualifiers = false
    let partsData = []
    let curQuestion = 0

    const radioChanged = () => {
      nextBtnDisabled.value = false
    }

    const optionSelected = async () => {
      const nextQuestion = curQuestion + 1
      if (nextQuestion < questionData.value.length) {
        await gotoNextQuestion(nextQuestion)
        curQuestion = nextQuestion
      } else {
        handleQuestionsCompleted()
      }
    }

    const handleQuestionsCompleted = async () => {
      const chosenQualifiers = questionData.value
      const qualifiedProducts = ymmQualifiers.filterQualifiedParts(
        partsData,
        questionData.value
      )
      hideQualifierQuestions()
      qualifiedData.parts = qualifiedProducts
      qualifiedData.chosenQualifiers = chosenQualifiers
      qualifiedData.collectionQueryObj = ymmSelectionData.getCollectionSlug(qualifiedData.dropdownSelections, chosenQualifiers)
      setYMMQualifiedData(qualifiedData)
      const { ymmTitle, qualifiers, ymmId } = qualifiedData.collectionQueryObj
      await router.push({ name: 'Collection', params: { id: ymmTitle }, query: { qualifiers: qualifiers, id: ymmId } })
      qualifiersOpen.value = false
    }

    const gotoNextQuestion = async (_nextQuestion) => {
      nextBtnDisabled.value = true
      const nextQuestionSelector = `.radio-${_nextQuestion}`
      const curQuestionSelector = `.radio-${curQuestion}`
      await gsap.to(curQuestionSelector, {
        duration: 0.5,
        autoAlpha: 0,
        onComplete: () => {
          gsap.set(curQuestionSelector, { display: 'none' })
        }
      })
      gsap.set(nextQuestionSelector, { width: 'auto' })
      await gsap.to(nextQuestionSelector, {
        duration: 0.5,
        autoAlpha: 1,
        onStart: () => {
          gsap.set(nextQuestionSelector, { display: 'flex' })
        }
      })
    }

    const setupQualifiers = async ({ dropdownSelections, unqualifiedParts }, preSelected = false) => {
      qualifiedData.dropdownSelections = dropdownSelections
      qualifiersOpen.value = null
      const { Parts, Vehicles } = unqualifiedParts
      curQuestion = 0
      partsData = Parts
      questionData.value = ymmQualifiers.getQualifierSteps(Parts, Vehicles)
      hasQualifiers = questionData.value.length > 0
      if (hasQualifiers) {
        showQualifierQuestions()
      } else {
        handleQuestionsCompleted()
      }
    }

    const showQualifierQuestions = async () => {
      qualifiersOpen.value = true
      nextBtnDisabled.value = true
      gsap.set('.el-radio-group', { autoAlpha: 0, display: 'none' })
      gsap.set('.radio-0', { autoAlpha: 1, display: 'flex' })
      gsap.set(['.ymm-qualifier-container'], { display: 'flex' })
      gsap.set('.ymm-qualifier-container', { autoAlpha: 0, scale: 0.95 })

      gsap.to('.ymm-qualifier-container', {
        autoAlpha: 1,
        scale: 1,
        duration: 0.5
      })
    }

    const hideQualifierQuestions = () => {
      if (hasQualifiers) gsap.to('.ymm-qualifier-container', { autoAlpha: 0, scale: 0.9 })
      return gsap.set('.ymm-qualifier-container', { display: 'none' })
    }

    return {
      radioChanged,
      optionSelected,
      setupQualifiers,
      hideQualifierQuestions,
      questionData,
      radioGroupRef,
      nextBtnDisabled,
      qualifiersOpen
    }
  }
}
</script>

<style lang="scss">
.ymm-qualifier-container {
  width: 100%;
  background-color: white;
  height: 100%;
  justify-content: center;
  align-items: center;

  display: none;
  button{
    min-width:168px;
    margin-bottom: 20px;
    @include mf-bold(inherit);
  }
  .ymm-header{
    h1{
      text-transform:uppercase;
    }
  }
  p {
    margin: 0 50px;
    margin-bottom: 10px;
    max-width: 580px;
    text-align: center;
    margin: 0 auto;
    border-bottom: $mf-border-color solid thin;
    padding-bottom: 30px;
    margin-bottom: 34px;
  }
  .question-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    justify-content: center;
    .el-radio{
      width: 100%;
      margin: 0px;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: var(--link-color);
      background: var(--link-color);
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: black;
    }
    .el-radio__label {
      display: flex;
      .base-question {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
}
.el-radio-group {
  opacity: 0;
  visibility: hidden;
  display: none;
  flex-direction: column;
  width: 0;
}
.radio-0 {
  opacity: 1;
  visibility: visible;
  display: flex;
  width: auto;
}
</style>

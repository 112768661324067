import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import ButterCMS from 'buttercms'

export async function getClientCMSData () {
  const cmsKey = getClientCMSKey()
  return await getCMSConfigObject(cmsKey)
}

export function getClientCMSKey () {
  const defaultMicrosite = process.env.VUE_APP_SELECTED_ENV === 'prod'
    ? process.env.VUE_APP_SELECTED_MICROSITE : process.env.VUE_APP_SELECTED_MICROSITE === 'autozone'
      ? 'autozone-beta' : process.env.VUE_APP_SELECTED_MICROSITE === 'autozonepro'
        ? 'autozonepro-beta' : process.env.VUE_APP_SELECTED_MICROSITE

  const host = window.location.host
  const splitMF = host.split('.')
  let microsite = (splitMF.length <= 0 || splitMF[0].includes('localhost') || splitMF.some((str) => str.includes('azurestaticapps')))
    ? defaultMicrosite : splitMF[0]

  if (!microsite.includes('microsite-')) microsite = 'microsite-' + microsite

  return microsite
}

export async function getCMSConfigObject (_resource) {
  const apiResourceGroup = _resource
  const cmsKeyQuery = getAPIObjFor('CMSApiKey')
  let getCMSKeyForMicrosite = false
  getCMSKeyForMicrosite = await cmsKeyQuery(_resource)
  const cmsApiResourceKey = getCMSKeyForMicrosite || 'nokey'
  const Butter = ButterCMS(cmsApiResourceKey)
  const cmsData = await Butter.page.retrieve('*', apiResourceGroup)
    .then(function (resp) {
      return resp.data.data
    })
    .catch(function (resp) {
      console.error('%cERROR Butter Data, using default values:', 'color:red', resp)
      return false
    })

  return cmsData
}

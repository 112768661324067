/* eslint-disable */
// const environment = process.env.VUE_APP_SELECTED_MICROSITE === 'demo' 
//   ? 'dev' : process.env.VUE_APP_SELECTED_ENV 
//   ? process.env.VUE_APP_SELECTED_ENV : 'dev'
const environment = process.env.VUE_APP_SELECTED_ENV || 'dev'

const microsite = process.env.VUE_APP_SELECTED_MICROSITE === 'autozonepro' 
  ? 'autozone' : process.env.VUE_APP_SELECTED_MICROSITE
  ? process.env.VUE_APP_SELECTED_MICROSITE : 'demo'


// Middleware API Config
const getMiddlewareApiConfig = {
  dev: {
    ymmApiUrl: 'https://20230112mwmsmaindatamicrosite.azurewebsites.net/api',
    baseApiUrl: 'https://20230112mwmsheadlessdatasvc.azurewebsites.net/api',
    authClientKey: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Inplc1M5eUdocjFXMmRjc1BXMkEtZiJ9.eyJpc3MiOiJodHRwczovL2Rldi1mcnRkamQ3Ny51cy5hdXRoMC5jb20vIiwic3ViIjoiVkt5WmIxMDhoODFxQVQ5a2F2a2ZBanU5eGppUEpuallAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vaGVhZGxlc3NtaWRkbGV3YXJlLmF6dXJld2Vic2l0ZXMubmV0IiwiaWF0IjoxNzMzMTc3Nzg2LCJleHAiOjE3MzU3Njk3ODYsInNjb3BlIjoicmVhZDp2ZWhpY2xlcyByZWFkOnBhcnRzIiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIiwiYXpwIjoiVkt5WmIxMDhoODFxQVQ5a2F2a2ZBanU5eGppUEpualkifQ.YyElbzuByZuOyvrqbJG_1KiJ-IquDNgnZTOtFFsHd_Iws2kXoIz7L3AQ25jTLJgscqVWy_GfouDMXU1rvzcO-d9wCK_-AREg-z6FApF35WSppAbUzcJZ7cJdDyUbSXIKLP3L9qSKhcDIe1PwIxeacztCNO9pGUs1ufXV8BFEF6GLdk5H6CfFSdzp0cK-ahZmOKmUcCW-99QhgmfuIAxZeimKsXryKz-2UP-l0joxWz0mBu87_fnHD5sa3j4zf_KkB-XvpsQfmXyYNxSnMPEIDJfLNs1AwWq3k83tU4YQDP_R5CUt7SCjJLiSqgh2lOHdvLdqdgdFkdexYFxkbdFURQ'
  },
  prod: {
    // ymmApiUrl: 'https://prodmiddlewareheadlessymmapi.azurewebsites.net/api',
    // baseApiUrl: 'https://prodmiddlewareheadlessapi.azurewebsites.net/api',
    ymmApiUrl: 'https://20230112mwmsmaindatamicrosite.azurewebsites.net/api',
    baseApiUrl: 'https://20230112mwmsheadlessdatasvc.azurewebsites.net/api',
    authClientKey: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Inplc1M5eUdocjFXMmRjc1BXMkEtZiJ9.eyJpc3MiOiJodHRwczovL2Rldi1mcnRkamQ3Ny51cy5hdXRoMC5jb20vIiwic3ViIjoiVkt5WmIxMDhoODFxQVQ5a2F2a2ZBanU5eGppUEpuallAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vaGVhZGxlc3NtaWRkbGV3YXJlLmF6dXJld2Vic2l0ZXMubmV0IiwiaWF0IjoxNzMzMTc3Nzg2LCJleHAiOjE3MzU3Njk3ODYsInNjb3BlIjoicmVhZDp2ZWhpY2xlcyByZWFkOnBhcnRzIiwiZ3R5IjoiY2xpZW50LWNyZWRlbnRpYWxzIiwiYXpwIjoiVkt5WmIxMDhoODFxQVQ5a2F2a2ZBanU5eGppUEpualkifQ.YyElbzuByZuOyvrqbJG_1KiJ-IquDNgnZTOtFFsHd_Iws2kXoIz7L3AQ25jTLJgscqVWy_GfouDMXU1rvzcO-d9wCK_-AREg-z6FApF35WSppAbUzcJZ7cJdDyUbSXIKLP3L9qSKhcDIe1PwIxeacztCNO9pGUs1ufXV8BFEF6GLdk5H6CfFSdzp0cK-ahZmOKmUcCW-99QhgmfuIAxZeimKsXryKz-2UP-l0joxWz0mBu87_fnHD5sa3j4zf_KkB-XvpsQfmXyYNxSnMPEIDJfLNs1AwWq3k83tU4YQDP_R5CUt7SCjJLiSqgh2lOHdvLdqdgdFkdexYFxkbdFURQ'
  }
}
export const MiddlewareApiConfig = getMiddlewareApiConfig[environment]


// Middleware Endpoint Config
const getMiddlewareEndpointConfig = {
  demo: {
    accessToken: '/v1/Auth0/AccessToken',
    ymmsAll: '/v2/autozone/YmmsAll',
    partSearch: '/v2/AutoZone/Parts/Search',
    vehiclesByPartNumber: '/v2/autozone/Vehicles/ByPartNumber',
    partsByYmmsCategory: '/v2/autozone/Parts/ByYmmsCategory',
    cmsApiKey: '/v1/Cms/Microsite/Key',
    makeModelSearch: '/v2/AutoZone/MakeModel/Search'
  },
  autozone: {
    accessToken: '/v1/Auth0/AccessToken',
    ymmsAll: '/v2/autozone/YmmsAll',
    partSearch: '/v2/AutoZone/Parts/Search',
    vehiclesByPartNumber: '/v2/autozone/Vehicles/ByPartNumber',
    partsByYmmsCategory: '/v2/autozone/Parts/ByYmmsCategory',
    cmsApiKey: '/v1/Cms/Microsite/Key',
    makeModelSearch: '/v2/AutoZone/MakeModel/Search'
  }
}
export const MiddlewareEndpointConfig = getMiddlewareEndpointConfig[microsite]


// Report Issue Config
const getReportIssueConfig = {
  all: {
    functionUrlEndpoint: 'https://headlessbuildersubmissionemails.azurewebsites.net/api/SendMicrositeReportIssueEmail',
    functionAccessToken: 'ugEHKpp4wRKc2wVBqA6BmZJMHEzwfT5ZrvhB1n2IfGVdzUU4Au2l33wxNPqGWm5CfTaSvAxNpIfSN2t6ZRggC-SWIOKz1Rl0WAMIcajXxL-1eArvwLa-KyJXACU0n0FN'
  }
}
export const ReportIssueConfig = getReportIssueConfig['all']

<template>
  <div class="dropdown-component">
    <div class="label" style='display:none'>{{ name }}</div>
    <div class="select-container">
      <el-select
        :class="getStatusColorClass(selectedValue)"
        ref="dropdown"
        v-model="selectedValue"
        filterable
        :filter-method="filterMethod"
        :disabled="dropdownDisabled"
        :placeholder="placeholderText"
        teleported
        @visible-change="visibleChange"
        @change="handleChange"
      >
        <el-option
          v-for="(item, index) in filteredOptions"
          :key="`${name}-${index}`"
          :label="getLabel(item)"
          :value="item"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, computed, onMounted } from 'vue'
import { getAPIObjFor } from '@/lib/ymm/YMMApi'
import { useStore } from 'vuex'

const STATE_NEEDS_PRIOR_DATA = 'needs-prior-data'
const STATE_LOADING = 'loading'
const STATE_LOADED = 'loaded'

export default defineComponent({
  props: {
    labelKey: String,
    valueKey: { type: String, default: '' },
    name: String,
    next: String
  },

  emits: ['OPTION_SELECTED'],

  setup (props, { emit }) {
    const options = ref([])
    const filteredOptions = ref([])
    const selectedValue = ref('')
    const activeQueryParam = ref('')
    const dropdown = ref(null)
    const dropdownDisabled = ref(true)
    const dropdownState = ref(STATE_NEEDS_PRIOR_DATA)
    const getApiQueryData = getAPIObjFor(props.name)
    const dropdownName = ref('')
    const store = useStore()

    const loadOptionData = (optionsData) => {
      dropdownState.value = STATE_LOADING
      activeQueryParam.value = 'year'
      options.value = filteredOptions.value = optionsData
      dropdownState.value = STATE_LOADED
      dropdownDisabled.value = false
    }

    const getDataNotAvailable = () => {
      return `Selected ${props.name} - No Data Available`
    }
    const dataNotAvailable = getDataNotAvailable()

    const getLabel = (_item) => {
      return _item.label
    }

    const getValue = (_item) => {
      return { value: _item.value, label: _item.label }
    }

    const selectedOptionData = () => {
      const { name } = props
      const selectedVal = selectedValue.value
      let nextValue = props.next
      if (selectedVal === dataNotAvailable) {
        nextValue = dataNotAvailable
      }
      const selectedData = {
        [`${name}`]: {
          value: selectedVal.value,
          label: selectedVal.label,
          next: nextValue
        }
      }
      return selectedData
    }

    const placeholderText = computed(() => {
      let text = ''
      if (dropdownState.value === STATE_NEEDS_PRIOR_DATA) {
        text = props.name
      } else if (dropdownState.value === STATE_LOADING) {
        text = 'Loading Options...'
      } else if (dropdownState.value === STATE_LOADED) {
        text = `Select a ${props.name}`
      }
      return text
    })

    const disableDropdown = async () => {
      dropdownState.value = STATE_NEEDS_PRIOR_DATA
      selectedValue.value = ''
      dropdownDisabled.value = true
    }
    // gets called from YMMDropdowns.vue in resetDropdowns and optionSelectedHandler
    const updateSelections = async (_newSelections, _preSelectData = null) => {
      const queryData = await getApiQueryData(_newSelections)
      if (queryData) {
        // await requestOptionData(queryData)
        await loadOptionData(queryData)
        // if there is pre selected data, autoselect it
        // else if data has only ONE value, autoselect it
        if (_preSelectData) {
          setSelectedValue(_preSelectData)
        } else if (options.value.length === 1) {
          // setSelectedValue(getValue(options.value[0]))
          setSelectedValue(getValue(options.value[0]))
        }
      }
    }

    const setSelectedValue = (_value) => {
      const matchingOption = options.value.find((option) =>
        getValue(option).label === _value.label
      )
      if (matchingOption) {
        selectedValue.value = matchingOption
        emit('OPTION_SELECTED', selectedOptionData())
      } else {
        if (store.state.globalYmmModalOpened === true &&
            store.state.globalYmmModalData !== null) {
          if (store.state.displayNoDataAvailable === true) {
            selectedValue.value = dataNotAvailable
          }
          store.commit('switchGlobalYmmModal', {
            isOpen: true,
            displayNoDataAvailable: false,
            payload: store.state.globalYmmModalData
          })
          emit('OPTION_SELECTED', selectedOptionData())
        }
      }
    }

    const filterMethod = (_val) => {
      filteredOptions.value = options.value.filter((item) => {
        return getLabel(item).toLowerCase().startsWith(_val.toLowerCase())
      })
    }

    const visibleChange = async (_visible) => {
      if (_visible) { filteredOptions.value = options.value }
    }

    const handleChange = (val) => {
      emit('update:modelValue', val)
      emit('OPTION_SELECTED', selectedOptionData())
    }

    function getStatusColorClass (_text) {
      if (_text === dataNotAvailable) {
        return 'data-not-available'
      }
      return {}
    }

    onMounted(() => {
      dropdownName.value = props.name
    })

    return {
      getValue,
      getLabel,
      selectedOptionData,
      disableDropdown,
      updateSelections,
      filterMethod,
      visibleChange,
      handleChange,
      options,
      selectedValue,
      placeholderText,
      dropdown,
      dropdownDisabled,
      filteredOptions,
      dropdownName,
      getStatusColorClass
    }
  }
})
</script>

<style lang="scss">
.dropdown-component {
  min-width: 330px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
  .el-select__wrapper {
    height: 40px;
  }
  .el-input__inner::placeholder{
    color: $mf-font-color-grey-dark !important;
  }
  .el-select-dropdown__item{
    text-align: left;
  }
  .select-container {
    display: flex;
    min-width: 20px;
    flex-grow: 1;
  }

  .el-select {
    width: 100%;
  }

  .data-not-available {
    .el-input__inner {
      border-color: #F26100;
      color: #F26100;
    }
  }
}
</style>
